import { Text } from "@chakra-ui/react";

export default function SongcardDescription() {
  return (
    <>
      <Text fontSize="xl">
        A songcard is a digital music collectable. <br />
        <br />
        It contains at least one song and one piece of artwork. <br />
        <br />
        It can unlock access to exclusive content and rewards. <br />
        <br />
        It{`'`}s a way for artists to sell their music and for fans to show off
        their collections to their friends. <br />
        <br />
        Songcards is a new format for creating music products and provides fans
        a way to support their favourite artists.
        <br /> <br />
      </Text>
    </>
  );
}
