import {
  Center,
  Container,
  SimpleGrid,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import FeaturedCard from "./FeaturedCard";

export default function FeaturedCards() {
  const [data, setData] = useState([]);
  const [more, setMore] = useState([]);
  const [full, setFull] = useState([]);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const { colorMode } = useColorMode();
  const [loadingCards, setLoadingCards] = useState(true);
  const ref = useRef(null);
  const isInView = useInView(ref);

  const fetchData = async () => {
    try {
      const response = await fetch("https://api.songcards.io/feat-cards/");
      const jay = await response.json();
      const thedata = jay;
      setData(thedata);
      setLoadingCards(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAppend = async () => {
    data.push(...more);
  };

  const fetchMore = async () => {
    setLoadingMore(true);
    try {
      const response = await fetch("https://api.songcards.io/feat-cards/");
      const jaymore = await response.json();
      const themore = await jaymore;
      setMore(themore);
      await handleAppend();
      setLoadingMore(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchMore();
  }, [data]);

  useEffect(() => {
    fetchMore();
  }, [isInView]);

  return (
    <div>
      {!loadingCards && (
        <SimpleGrid columns={[1, null, 2]} spacing='25px'>
          {data.map((item, index) => (
              <FeaturedCard item={item} key={index} />
          ))}
          <br></br>
        </SimpleGrid>
      )}
      <Container mt={50}>
        <div ref={ref}>
          {!loadingMore && (
            <Center>
              <button onClick={fetchMore}>load more</button>
            </Center>
          )}
        </div>
        {loadingMore && (
          <Center>
            <Text>loading cards...</Text>
          </Center>
        )}
      </Container>
    </div>
  );
}

const containerStyle = {
  whiteSpace: "pre-line",
};
