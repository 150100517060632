import FeaturedCards from "@/components/FeaturedCards";
import SongCardDescription from "@/components/SongcardDescription";
import SubstackModal2 from "@/components/SubstackModal2";
import {
  Box,
  Center,
  Container,
  Divider,
  Grid,
  GridItem,
  Heading,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";

enum PillState {
  FEATURED = "Featured",
  ABOUT = "About",
}

export default function Discover() {
  const { colorMode } = useColorMode();
  const [pillSelect, setPillSelect] = useState<PillState>(PillState.FEATURED);

  return (
    <>
      <Head>
        <title>Songcards: Listen | Discover | Collect</title>
        <link rel="icon" href="/SC Icon_A_Light.svg" />
        <meta
          name="description"
          content="Discover new music and grow your collection"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta itemProp="image" content="https://songcards.io/sc-pink.png" />
        <link rel="canonical" href={`https://songcards.io/`} />
        <link rel="apple-touch-icon" href="/SC Icon_A_Light.svg" />
        <meta property="og:image" content="https://songcards.io/sc-pink.png" />
        <meta property="og:url" content="https://songcards.io/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Songcards: Listen | Discover | Collect"
        />
        <meta
          property="og:description"
          content="Discover new music and grow your collection"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Songcards: Listen | Discover | Collect"
        />
        <meta
          name="twitter:description"
          content="Discover new music and grow your collection"
        />
        <meta name="twitter:image" content="https://songcards.io/sc-pink.png" />
        <meta name="apple-itunes-app" content="app-id=6737206374" />
        <meta name="msvalidate.01" content="CA08E3D312D9A033EADE0130E2FE2CC1" />
      </Head>
      <>
        <Center>
          <Heading
            as="h1"
            size="2xl"
            letterSpacing={5}
            textAlign="center"
            maxW="sm"
            mb={5}
            mt={0}
          >
            LISTEN. DISCOVER.
            <div className="mr-2 ml-2 bg-[linear-gradient(to_right,rgba(255,153,213,0.9),rgba(255,102,196,0.9),rgba(255,102,196,0.9),rgba(255,102,196,0.9),rgba(255,153,213,0.9))] inline-block text-transparent bg-clip-text bg-[length:400%_auto] animate-gradient">
              COLLECT.
            </div>
          </Heading>
        </Center>
        <SubstackModal2 />
        <Center mt={5} mb={5}>
          <a
            href="https://apps.apple.com/us/app/songcards/id6737206374?itscg=30200&itsct=apps_box_badge&mttnsubad=6737206374"
            style={{ display: "inlineBlock" }}
            target="_blank"
          >
            <img
              src="https://toolbox.marketingtools.apple.com/api/v2/badges/download-on-the-app-store/black/en-us?releaseDate=1733443200"
              alt="Download on the App Store"
              style={{
                width: 123,
                height: 41,
                verticalAlign: "middle",
                objectFit: "contain",
              }}
            />
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.songcards.songcards&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <img
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              style={{
                width: 155,
                height: 100,
                verticalAlign: "middle",
                objectFit: "contain",
              }}
            />
          </a>
        </Center>
        <Center mt={4}>
          <Divider mb={10} mt={3} maxW={620} />
        </Center>
        <Center mb={100}>
          <Grid
            templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)" }}
            gap={4}
            justifyContent="center"
          >
            <GridItem>
              <Box
                borderWidth={1}
                borderRadius={30}
                width={200}
                borderColor={colorMode === "light" ? "black" : "white"}
                borderStyle={
                  pillSelect == PillState.FEATURED ? "hidden" : "dotted"
                }
                padding={3}
                textAlign="center"
                onClick={() => setPillSelect(PillState.FEATURED)}
                backgroundColor={
                  pillSelect == PillState.FEATURED
                    ? "rgba(255,153,213,0.9)"
                    : ""
                }
                textColor={pillSelect == PillState.FEATURED ? "black" : ""}
                _hover={{ cursor: "pointer" }}
              >
                Featured Songcards
              </Box>
            </GridItem>
            <GridItem>
              <Box
                borderWidth={1}
                borderRadius={30}
                width={200}
                borderColor={colorMode === "light" ? "black" : "white"}
                borderStyle={
                  pillSelect == PillState.ABOUT ? "hidden" : "dotted"
                }
                padding={3}
                textAlign="center"
                onClick={() => setPillSelect(PillState.ABOUT)}
                backgroundColor={
                  pillSelect == PillState.ABOUT ? "rgba(255,153,213,0.9)" : ""
                }
                textColor={pillSelect == PillState.ABOUT ? "black" : ""}
                _hover={{ cursor: "pointer" }}
              >
                What is a Songcard?
              </Box>
            </GridItem>
          </Grid>
        </Center>
        {pillSelect == PillState.ABOUT && (
          <Center mb={20} padding={5}>
            <Center maxW="lg" alignItems="center" justifyContent="center">
              <SongCardDescription />
            </Center>
          </Center>
        )}
        <Container maxWidth="2xl" mb={20}>
          <Center>
            <Heading size="lg">Discover Songcards</Heading>
          </Center>
          <br />
          <Center mb={5}>
            <Text>and grow your collection</Text>
          </Center>
          <FeaturedCards />
        </Container>
        <Center mb={300}>
          <Link href={`/`}>
            <Image
              src={
                colorMode === "dark"
                  ? "/SC Icon_B_White.svg"
                  : "/SC Icon_B_Black.svg"
              }
              width={30}
              height={30}
              alt="Discover more Songcards"
            />
          </Link>
        </Center>
      </>
    </>
  );
}
