import {
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Text,
  ModalOverlay,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";

export default function SubstackModal2() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();

  const buttonText = colorMode === "light" ? "text-black" : "text-white";
  const backgroundColor = colorMode === "light" ? "white" : "white";

  const buttonBG = colorMode === "light" ? "bg-white" : "bg-black";

  return (
    <>
      <Center>
        <Center>
          <button
            onClick={onOpen}
            className="mt-7 mb-5 rounded-2xl p-px bg-[linear-gradient(to_right,rgba(255,153,213,0.9),rgba(255,102,196,0.9),rgba(255,102,196,0.9),rgba(255,102,196,0.9),rgba(255,153,213,0.9))] bg-[length:400%_auto] animate-gradient"
          >
            <div
              className={`rounded-2xl p-3 w-100 ${buttonBG} font-semibold ${buttonText}`}
            >
              <Text>
              Join The Collector{`'`}s Club
              </Text>
            </div>
          </button>
        </Center>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent backgroundColor={backgroundColor}>
            <ModalCloseButton
              style={{ color: "black", marginRight: 20, marginTop: 5 }}
            />
            <ModalBody>
              <Center>
                <iframe
                  src="https://songcards.substack.com/embed"
                  width="100%"
                  height={320}
                  style={{ border: "1px solid #EEE", background: "white" }}
                ></iframe>
              </Center>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Center>
    </>
  );
}
