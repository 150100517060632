import {
  Card,
  CardBody,
  Center,
  Divider,
  HStack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useInView } from "framer-motion";
import Link from "next/link";
import { useRef } from "react";
import CardAboutModal from "./CardAboutModal";
import PlayBox from "./PlayBox";
import ThisIsModal from "./ThisIsModal";
import Thumbnail from "./Thumbnails";

interface ItemProp {
  item: {
    id: number;
    card: {
      id: number;
      uuid: string;
      image: string;
      inc_list: string[];
      title: string;
      ext_file?: string;
      comp_file?: string;
      thumbnail?: string;
      desc?: string;
      price?: number;
      includes?: string;

      song: {
        file?: string;
        title: string;
        ext_file?: string;
      };
      primary_artist: {
        name: string;
        slug: string;
      };
      files: [
        {
          file_name: string;
        }
      ];
    };
  };
}

const FeaturedCard = ({ item }: ItemProp) => {
  const { colorMode } = useColorMode();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div ref={ref}>
      <>
        <br />
        <Center
          style={{
            transform: isInView ? "none" : "translateY(+100px)",
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <Card
            maxW="xs"
            align="center"
            bgColor={colorMode == "dark" ? "black" : "white"}
            variant="outline"
            borderColor={colorMode == "dark" ? "white" : "black"}
            borderStyle="dotted"
            borderRadius={15}
          >
            <CardBody>
              <Link href={`/cards/${item.card.uuid}`}>
                <Thumbnail
                  ext_file={item.card.ext_file}
                  comp_file={item.card.comp_file}
                />
              </Link>
              <br />
              <Center>
              <PlayBox card={item.card} />
              </Center>
              <Text ml={4} mb={3} mt={5}>
                is a Songcard from
                <br />
                {item.card.primary_artist && (
                  <Text as="b" key={item.card.primary_artist.slug}>
                    <Link href={`/artists/${item.card.primary_artist.slug}`}>
                      {item.card.primary_artist.name}
                    </Link>
                  </Text>
                )}
              </Text>
              <Divider mb={5} />
              <Center>
                <HStack justify="space-between" w="94%">
                  <CardAboutModal
                    card={item.card}
                    price={false}
                    showOpen={true}
                  />
                  <ThisIsModal
                    card={item.card}
                    price={false}
                    showOpen={false}
                  />
                </HStack>
              </Center>
            </CardBody>
          </Card>
        </Center>
      </>
    </div>
  );
};

export default FeaturedCard;
